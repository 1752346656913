<template>
  <v-app>
    <v-app-bar app clipped-left color="green">
      <router-link to="/" >
        <v-icon>mdi-home</v-icon>
      </router-link>
      <!-- タイトル -->
      <span class="ml-3 mr-5 mb-0">TRPG <span class="font-weight-light">Calendar</span></span>
    </v-app-bar>

    <v-main class="green darken-4">
      <v-parallax class="green darken-4" height="450" :src="randomTitleImage()">
        <v-row align="center" justify="center">
          <v-col class="text-center" cols="12">
            <h1 elevation="3" class="display-2 font-weight-bold mb-4">404 Not Found</h1>
            <p elevation="3" class="subtitle font-weight-bold">ここにはなにもないようです・・・</p>
          </v-col>
        </v-row>
      </v-parallax>
    </v-main>

    <foot></foot>
  </v-app>
</template>

<script>
import Foot from './Footer';
export default {
  name: 'NotFound',

  components: {
    Foot,
  },

  data: () => ({
    titleImages: [
      require('@/assets/dragon_fire3_red.webp'),
      require('@/assets/fantasy_dark_elf.webp'),
      require('@/assets/character_cthulhu_cthugha.webp'),
      require('@/assets/character_cthulhu_shoggoth.webp'),
      require('@/assets/fantasy_kerberos.webp')
    ],
  }),

  methods: {
    randomTitleImage() {
      const rnd = Math.floor(Math.random() * this.titleImages.length);
      return this.titleImages[rnd];
    },
  },
}
</script>

<style scoped>
h1 {
  color: #fff;
  text-shadow: 3px 3px 15px #111;
}
p.subtitle {
  color: #fff;
  text-shadow: 2px 2px 5px #111;
}
p, h2 {
  color: #fff;
  text-shadow: 1px 1px 3px #111;
}
.text-body {
  max-width: 1200px;
  margin-left: auto!important;
  margin-right: auto!important;
}
</style>
