import { render, staticRenderFns } from "./NotFound.vue?vue&type=template&id=5466d8ac&scoped=true&"
import script from "./NotFound.vue?vue&type=script&lang=js&"
export * from "./NotFound.vue?vue&type=script&lang=js&"
import style0 from "./NotFound.vue?vue&type=style&index=0&id=5466d8ac&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5466d8ac",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VParallax } from 'vuetify/lib/components/VParallax';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VApp,VAppBar,VCol,VIcon,VMain,VParallax,VRow})
