<template>
  <v-app>
    <v-app-bar app clipped-left color="green">
      <router-link to="/" >
        <v-icon>mdi-home</v-icon>
      </router-link>
      <!-- タイトル -->
      <span class="ml-3 mr-5 mb-0">TRPG <span class="font-weight-light">Calendar</span></span>
    </v-app-bar>

    <v-main class="green darken-4">
      <v-container fluid>
        <v-row align="center" justify="center">
          <v-col class="text-center" cols="12">
            <h1 elevation="3" class="display-2 font-weight-bold mb-4">運営</h1>
            <p elevation="3" class="subtitle font-weight-bold">このサイトの運営について</p>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid class="">
        <v-row align="center" justify="center">
          <v-col class="" cols="12">
            <div class="text-body" justify="center">
              <h2 class="pt-6 my-1 mx-3">個人が運営するサイトです</h2>
              <hr />
              <p class="pt-4 my-0 mx-3">
              本サイト・アプリは管理人個人が運営するものです。
              </p>
              <p class="pt-4 my-0 mx-3">
              プログラムの練習がてら、欲しかったTRPGカフェ情報の検索アプリを作成したのですが、カフェの集客に役立つかもしれないと思い公開しております。
              </p>
              <h2 class="pt-6 my-1 mx-3">リンクフリーです</h2>
              <hr />
              <p class="pt-4 my-0 mx-3">
              当サイトはリンクフリーです。
              </p>
              <p class="pt-4 my-0 mx-3">
              もしお気に入りいただけたら、シェアしてみんなで使っていただければ幸いです。
              </p>
              <h2 class="pt-6 my-1 mx-3">みなさんTRPGカフェで楽しんでください！</h2>
              <hr />
              <p class="pt-4 my-0 mx-3">
              管理人は生活環境の変化により、しばらくTRPGからはなれてしまっています。
              </p>
              <p class="pt-4 my-0 mx-3">
              しかし、TRPGカフェのプレイヤー募集情報をながめているだけでも、楽しい気分になります。
              </p>
              <p class="pt-4 my-0 mx-3">
              そんなカフェの活動も、参加するみなさんがいなければ成り立ちませんので（お前が言うなという感じですが）、すこしでも集客の役にたてばと思っています。
              </p>
              <p class="pt-4 my-0 mx-3">
              そんなわけで、みなさまぜひTRPGカフェに足を運んで楽しんできてください！
              </p>
              <p class="pt-4 my-0 mx-3">
              私も必ず復帰します。
              </p>
              <h2 class="pt-6 my-1 mx-3">各TRPGカフェに一切の責任はありません</h2>
              <hr />
              <p class="pt-4 my-0 mx-3">
              掲載情報は管理人個人の責任において収集、公開するものであり、ご提供いただいている各TRPGカフェは本サイト掲載情報になんら責任を負いません。
              </p>
              <h2 class="pt-6 my-1 mx-3">広告について</h2>
              <hr />
              <p class="pt-4 my-0 mx-3">
              ひょっとしたらgoogle等の広告をクリックされた方もいらっしゃるかもしれません。
              </p>
              <p class="pt-4 my-0 mx-3">
              そちらは自費で、使ってくれてお店の来客が増えたらなぁという趣味の範囲で掲載しており、お店から何らか提供を受けているわけではありません。
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <foot></foot>
  </v-app>
</template>

<script>
import Foot from './Footer';
export default {
  name: 'About',

  components: {
    Foot,
  },
}
</script>

<style scoped>
h1 {
  color: #fff;
  text-shadow: 3px 3px 15px #111;
}
p.subtitle {
  color: #fff;
  text-shadow: 2px 2px 5px #111;
}
p, h2 {
  color: #fff;
  text-shadow: 1px 1px 3px #111;
}
.text-body {
  max-width: 1200px;
  margin-left: auto!important;
  margin-right: auto!important;
}
</style>
