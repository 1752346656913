import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import vuetify from './plugins/vuetify';

import Search from '@/components/Search'
import About from '@/components/About'
import Privacy from '@/components/Privacy'
import NotFound from '@/components/NotFound'

Vue.config.productionTip = false
Vue.use(VueRouter)

const routes = [
  { path: '/',          component: Search},
  { path: '/about',     component: About },
  { path: '/privacy',   component: Privacy },
  { path: '*',          component: NotFound }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

new Vue({
  vuetify,
  render: h => h(App),
  router: router
}).$mount('#app')
