<template>
  <v-app>
    <v-app-bar app clipped-left color="green">
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
      />
      <!-- タイトル -->
      <span class="ml-3 mr-5 mb-0">TRPG <span class="font-weight-light">Calendar</span></span>
      <!-- テキスト検索 -->
      <v-text-field
        v-model="filterSet.keywordBuffer"
        @keyup.enter="setFilterKeyword"
        @input="onChangeFilterKeyword"
        label="検索ワードを入力(クトゥルフ、○○さん、等)"
        prepend-inner-icon="mdi-search"
        solo-inverted
        flat
        hide-details
        clearable
      />
      <v-spacer />
    </v-app-bar>

    <!-- サイドバー -->
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      color="grey lighten-4"
    >
      <v-list dense class="grey lighten-4">
        <!-- お気に入り表示切り替えボタン -->
        <v-btn
          @click="handleFilter('favorite', true, 999, 'btn')"
          :class="[
            filterSet.showFavoriteList === true ? 'ml-3 px-5 mb-2 pink lighten-1' : 'ml-3 px-5 mb-2 grey lighten-1'
          ]"
          rounded
          dark
        >
        <v-icon
          v-if="!filterSet.showFavoriteList"
        >mdi-checkbox-blank-outline</v-icon>
        <v-icon
          v-if="filterSet.showFavoriteList"
        >mdi-checkbox-marked-outline</v-icon>お気に入り</v-btn>
        <!-- フィルターメニュー(dataのmenuItemsの内容に従って生成) -->
        <template v-for="(item, i) in menuItems">
          <!-- 見出し用テンプレート  -->
          <v-row
            v-if="item.heading"
            :key="i"
            align="center"
          >
            <v-col cols="12" class="text-center">
              <v-subheader
                v-if="item.heading"
              >
                {{ item.heading }}
              </v-subheader>
            </v-col>
          </v-row>
          <!-- 区切り線用テンプレート  -->
          <v-divider
            v-else-if="item.divider"
            :key="i"
            dark
            class="my-4"
          />
          <!-- フィルター用テンプレート -->
          <v-list-item
            v-else
            @click="handleFilter(item.filterType, item.filterValue, i, item.group)"
            :key="i"
            :class="[
              item.filterIs === true ? 'green' : ''
            ]"
            :dark="item.filterIs"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title
                :class="[
                  item.filterIs === true ? 'font-weight-black' : ''
                ]"
              > 
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon
                v-if="item.filterIs"
              >mdi-check</v-icon>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <!-- メイン画面 -->
    <v-main>
      <!-- タイトル-->
      <v-parallax class="green darken-4" height="450" :src="randomTitleImage()">
        <v-row align="center" justify="center">
          <v-col class="text-center" cols="12">
            <h1 elevation="3" class="display-4 font-weight-bold mb-4">TRPGカレンダー検索(仮)</h1>
            <p elevation="3" class="subtitle font-weight-bold">みんながつくる、世界にたったひとつの物語</p>
          </v-col>
        </v-row>
        <v-row align="center" justify="center" class="navlink-parent">
          <v-col class="navlink-children" cols="12">
            <v-btn
              text
              class="my-0 ml-3 px-3"
              to="/about"
              rounded
              dark
              outlined
            >
              運営について
            </v-btn>
            <v-btn
              text
              class="my-0 ml-3 px-3"
              to="/privacy"
              rounded
              dark
              outlined
            >
              プライバシー
            </v-btn>
          </v-col>
        </v-row>
      </v-parallax>

      <!-- ボディ-->
      <v-container fluid class="grey lighten-4">
        <v-row justify="center" align="start">
          <!-- 読込中表示 -->
          <v-progress-circular
            v-if="isLoading"
            :size="70"
            :width="8"
            indeterminate
            color="green"
          >
            更新中
          </v-progress-circular>
          <!-- メインコンテンツ -->
          <v-col class="py-0 my-0" cols="12">
            <p
              v-if="!isLoading"
              class="py-0 my-0 mx-3"
            >
              {{ recordInformation.lastUpdatedDate }} 更新 - {{ selectedSessions.length }}セッションヒットしました(最大100件表示しています)
            </p>
          </v-col>
          <v-col
            class="py-0 my-3"
            cols="12"
            color="grey lighten-1"
          >
            <p
              class="py-0 my-0 mx-3"
              >
              <span>本サイトの情報は、データ収集時点の過去のものです。最新情報は「募集中」ボタンから各カフェのサイトを御覧ください。</span>
            </p>
            <p
              class="py-0 my-0 mx-3"
              >
            </p>
          </v-col>
          <v-col cols="12">
            <sessions
              v-for="session in this.selectedSessionsView"
              @toggle-favorite="toggleFavorite"
              @add-keyword-game-system="addKeywordGameSystem"
              :session="session"
              :key="session.id"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <foot></foot>
  </v-app>
</template>

<script>
import Sessions from './Sessions';
import Foot from './Footer';
import axios from 'axios';
// import JSZip from 'jszip';

export default {
  name: 'Search',

  components: {
    Sessions,
    Foot,
  },

  data: () => ({
    titleImages: [
      require('@/assets/dragon_fire3_red.webp'),
      require('@/assets/fantasy_dark_elf.webp'),
      require('@/assets/character_cthulhu_cthugha.webp'),
      require('@/assets/character_cthulhu_shoggoth.webp'),
      require('@/assets/fantasy_kerberos.webp')
    ],
    sessionsRaw: [],
    sessions: [],
    recordInformation: {
      lastUpdatedDate: '2020-0?-0?'
    },
    favoriteUrlList: [],
    filterSet: {
      isDeleted: {value: false, default: false},
      isPast: {value: false, default: false},
      statusId: {value: 1, default: 1}, // 数値 0:すべて 1:募集中 2:募集終了 3:中止
      areaId: {value: "all", default: "all"}, // 文字列 0:すべて
      days: {value: 9999, default: 9999}, // 数値 9999:すべて
      weeks: {value: 9999, default: 9999}, // 数値 9999:すべて
      keywords: [],
      keywordBuffer: "",
      showFavoriteList: false
    },
    menuItems: [
      //{ heading: '絞り込みフィルター' },
      { group: 'date', icon: 'mdi-calendar-today', text: '今日開催', filterType: 'days', filterValue: 0, filterIs: false },
      { group: 'date', icon: 'mdi-calendar-today', text: '明日開催', filterType: 'days', filterValue: 1, filterIs: false },
      // { group: 'date', icon: 'mdi-calendar-week', text: '今週開催', filterType: 'weeks', filterValue: '0', filterIs: false },
      // { group: 'date', icon: 'mdi-calendar-week', text: '来週開催', filterType: 'weeks', filterValue: '1', filterIs: false },
      { divider: true },
      { group: 'area', icon: 'mdi-map-marker', text: '神田', filterType: 'areaId', filterValue: 'kanda', filterIs: false },
      { group: 'area', icon: 'mdi-map-marker', text: '池袋', filterType: 'areaId', filterValue: 'ikebukuro', filterIs: false },
      { group: 'area', icon: 'mdi-map-marker', text: '神楽坂', filterType: 'areaId', filterValue: 'kagurazaka', filterIs: false },
      { divider: true },
      { group: 'status', icon: 'mdi-account', text: 'すべて', filterType: 'statusId', filterValue: 0, filterIs: false },
      { group: 'status', icon: 'mdi-account', text: '募集中', filterType: 'statusId', filterValue: 1, filterIs: true },
      { group: 'status', icon: 'mdi-account', text: '募集終了', filterType: 'statusId', filterValue: 2, filterIs: false },
      { divider: true },
      { group: 'reset', icon: 'mdi-close-circle', text: 'リセット', filterType: 'reset', filterValue: '', filterIs: false },
    ],
    analytics: {
      gameSystem: {
        openSessions: [
          {
            name: 'AD&D',
            number: 13
          },
          {
            name: 'クトゥルフの呼び声',
            number: 9
          },
          {
            name: 'ルーンクエスト',
            number: 7
          },
          {
            name: 'T&T',
            number: 4
          },
          {
            name: 'フォーリナー',
            number: 1
          },
          {
            name: 'ストームブリンガー',
            number: 1
          },
          {
            name: '混沌の渦',
            number: 1
          },
          {
            name: 'GURPS',
            number: 1
          },
          {
            name: 'ビヨンド ローズ トゥ ロード',
            number: 1
          }
        ]
      }
    },
    isOffline: false,
    isLoading: true,
    drawer: null,
  }),

  methods: {
    randomTitleImage() {
      const rnd = Math.floor(Math.random() * this.titleImages.length);
      return this.titleImages[rnd];
    },
    async getSessions() {
      console.log(`--- getSession()`);
      try {
        const sessionsUrl = process.env.VUE_APP_SESSIONS_URL;
        console.log({sessionsUrl: sessionsUrl});
        const response = await axios.get(sessionsUrl);
        console.log({response: response});

        // const new_zip = new JSZip();
        // new_zip.loadAsync(response.data)
        //   .then((zip) => {
        //     // zip.file("hello.txt").async("string");
        //     zip.file("hello.txt").async("string");
        //     console.log(zip);
        //   });

        this.sessionsRaw = response.data.sessions;
        console.log(`--- got sessionsRaw:  ${this.sessionsRaw.length} records.`);
        console.log(`--- converting data...`);
        this.getLastUpdatedDate();
        this.decodeURI();
        this.addFilterDate();
        this.encodeText();
        this.createPreviewText();
        await this.checkFavoriteListOnLoad();
        console.log(`--- done.`);
        this.sessions = this.sessions.concat(this.sessionsRaw);
        console.log(`--- copied sessionsRaw to sessions.`);
        this.isLoading = false;
        console.log(`--- finished load.`);
      } catch (error) {
        this.isOffline = true;
        this.isLoading = false;
        console.error(error)
      }
    },
    decodeURI() {
      this.sessionsRaw.forEach(session => {
        session.url = decodeURIComponent(session.url);
        session.url = decodeURIComponent(session.url);
        session.venue_url = decodeURIComponent(session.venue_url);
      });
    },
    addFilterDate() {
      // daysとweeksを付与したい
      this.sessionsRaw.forEach(session => {
        const now = new Date();
        const nowValue = parseInt(`${now.getFullYear()}${("0" + (now.getMonth() + 1)).slice(-2)}${("0" + (now.getDate())).slice(-2)}`);
        // isToday & isPast
        session.isToday = false;
        if(session.date_value >= nowValue) {
          session.isPast = false;
          if(session.date_value == nowValue) {
            session.isToday = true;
          }
        } else if(session.date_value < nowValue) {
          session.isPast = true;
          session.status = "過去の情報";
          session.status_id = 2;
        }
        // 経過日数 date値の引き算（1000分の1秒）を日付まで丸めて小数点以下切り捨て
        session.days = Math.floor((session.date - now) / (24 * 60 * 60 * 1000));
        // 経過日数 date値の引き算（1000分の1秒）を日付まで丸めて小数点以下切り捨て
        session.weeks = Math.floor((session.date - now) / (60 * 60 * 1000));
      });
    },
    getLastUpdatedDate() {
      let d = this.sessionsRaw.map(session => {
        let time = new Date(session.last_modified_date);
        time = time.getTime();
        return time;
      });
      d = Math.max(...d);
      d = new Date(d);
      this.recordInformation.lastUpdatedDate = (d.getMonth() + 1)
        + "月"
        + d.getDate()
        + "日 "
        + ("0" + d.getHours()).slice(-2)
        + ":"
        + ("0" + d.getMinutes()).slice(-2)
    },
    encodeText() {
      this.sessionsRaw.forEach((session) => {
        session.title = this.encode(session.title);
        session.author = this.encode(session.author);
        session.description = this.encode(session.description);
        session.description = session.description.replace(/&ensp;/g, "").replace(/&emsp;/g, "").replace(/&thinsp;/g, "").replace(/&nbsp;/g, "");
      });
    },
    encode(text) {
      return text
        .replace(/&lt;/g, '＜')
        .replace(/&gt;/g, '＞')
        .replace(/&quot;/g, '"')
        .replace(/&#39;/g, '\'')
        .replace(/&amp;/g, '&');
    },
    createPreviewText() {
      this.sessionsRaw.forEach((session) => {
        if (typeof session.description === "undefined" || session.description === null || session.description === "") {
          session.previewText = "...";
        } else {
          session.previewText = session.description.slice(0, 200) + "...";
        }
      });
    },

    // フィルター入力関連
    handleFilter(type, value, i, group) {
      const selectedItem = this.menuItems[i];
      switch (type) {
        case 'statusId':
          if (this.filterSet.statusId.value === value) {
            break;
          } else {
            this.menuItems[i].filterIs = !this.menuItems[i].filterIs;
            this.filterSet.statusId.value = value;
            break;
          }
        case 'areaId':
          if (this.filterSet.areaId.value === value) {
            this.menuItems
              .filter(e => e.group === 'area')
              .map(e => e.filterIs = false);
            this.filterSet.areaId.value = 'all';
            break;
          } else {
            selectedItem.filterIs = !selectedItem.filterIs;
            this.filterSet.areaId.value = value;
            break;
          }
        case 'days':
          if (this.filterSet.days.value === value) {
            this.menuItems
              .filter(e => e.group === 'date')
              .map(e => e.filterIs = false);
            this.filterSet.days.value = 9999;
            break;
          } else {
            selectedItem.filterIs = !selectedItem.filterIs;
            this.filterSet.days.value = value;
            break;
          }
        case 'keywords':
          this.menuItems[i].filterIs = !this.menuItems[i].filterIs;
          this.filterSet.keywords = value;
          break;
        case 'favorite':
          this.filterSet.showFavoriteList = !this.filterSet.showFavoriteList;
          break;
        case 'reset':
          this.filterSet.statusId.value  = this.filterSet.statusId.default; 
          this.filterSet.areaId.value    = this.filterSet.areaId.default;   
          this.filterSet.days.value      = this.filterSet.days.default;     
          this.menuItems.forEach(menuItem => menuItem.filterIs = false);
          this.menuItems.forEach(menuItem => {
            if(menuItem.filterType === "statusId" && menuItem.filterValue === 1) {
              menuItem.filterIs = true;
            }
          });
          this.filterSet.keywordBuffer = "";
          this.filterSet.keywords = [];
          break;
        default:
          break;
      }
      // 同group内の他ハイライト表示を解除
      this.menuItems.filter(menuItem => menuItem.group === group && (menuItem.filterType + menuItem.filterValue) !== (type + value)).forEach(menuItem => menuItem.filterIs = false);
      // 同group内の他typeフィルターを解除
      this.menuItems.filter(menuItem => menuItem.group === group && menuItem.filterType !== type).forEach(menuItem => this.filter[menuItem.filterType].value = this.filter[menuItem.filterType].default);
      // console.log(`type = ${type}  value = ${value}  filteris = ${selectedItem.filterIs}  filterValue = ${selectedItem.filterValue}`);
      // console.log({filterSet: this.filterSet});
    },
    setFilterKeyword() {
      if(this.filterSet.keywordBuffer === null) {
        this.filterSet.keywords = [];
        return
      } else {
        this.filterSet.keywords = this.filterSet.keywordBuffer
          .split(/[\u{20}\u{3000}]/u)
          .filter(keyword => keyword !== "");
        console.log({keywordBuffer : this.filterSet.keywordBuffer});
        console.log({keywords : this.filterSet.keywords});
      }
    },
    onChangeFilterKeyword() {
      if(this.filterSet.keywordBuffer === null) {
        this.filterSet.keywords = [];
      }
    },
    filterSetDays(days) {
      console.log(`this.filterSet.days.value is ${this.filterSet.days.value}`);
      this.filterDays = days;
    },
    checkDays(date) {
      if(this.filterSet.days.value === 9999) {
        return true;
      } else {
        const now = new Date();
        const sessionDate = new Date(date)
        const days = Math.floor((sessionDate.getTime() + 540 * 60 * 1000)/(24 * 60 * 60 * 1000)) - Math.floor((now.getTime() + 540 * 60 * 1000)/(24 * 60 * 60 * 1000));
        if(days == this.filterSet.days.value) {
          return true;
        } else {
          return false
        }
      }
    },
    checkArea(venue) {
      if(this.filterSet.areaId.value === "all") {
        return true;
      } else if(this.filterSet.areaId.value === "kanda" && venue.indexOf("神田 Daydream") > -1) {
        return true;
      } else if(this.filterSet.areaId.value === "ikebukuro" && venue.indexOf("池袋 Mistel") > -1) {
        return true;
      } else if(this.filterSet.areaId.value === "kagurazaka" && venue.indexOf("神楽坂 モノドラコ") > -1) {
        return true;
      } else {
      return false;
      }
    },
    checkStatus(sessionStatusId) {
      if(this.filterSet.statusId.value === 0) {
        return true;
      } else {
        return sessionStatusId === this.filterSet.statusId.value;
      }
    },
    checkGameSystem(gameSystemName) {
      // 検索キーワードが未入力ならtrue
      if(this.filterSet.keywords.length === 0) {
        return true;
      } else {
        // 正規表現リストを作成
        const regexs = this.filterSet.keywords
          .filter(word => word.indexOf("#") > -1)
          .map(word => {
            word = word.replace("#", "");
            return new RegExp(`${word}`, 'i');
          });
        // 正規表現リストがゼロならtrue
        if(regexs.length === 0) {
          return true;
        } else {
          // マッチしたかどうかリストを作成
          const matchs = regexs.map(regex => {
            if(gameSystemName.match(regex)) {
              return true;
            } else {
              return false;
            }
          });
          // すべてのマッチリストがtrue なら最終結果trueを返す
          if(matchs.length === 1) {
            return matchs[0];
          } else {
            let matchResult = true;
            for(let i = 0;i < matchs.length;i++) {
              matchResult = (matchResult || matchs[i]);
            }
            return matchResult;
          }
        }
      }
    },
    checkKeyword(title, dateStrings, author, description, gameSystemName) {
      // nullを消去
      if(author === null) { author = "_"; }
      if(description === null) { description = "_"; }
      // 検索キーワードが未入力ならtrue
      if(this.filterSet.keywords.length === 0) {
        return true;
      } else {
        // 正規表現リストを作成
        console.log(`keywords are ${this.filterSet.keywords}`);
        const regexs = this.filterSet.keywords
          .filter(word => word.indexOf("#") === -1)
          .map(word => new RegExp(`.*${word}.*`, 'i'));
        // マッチしたかどうかリストを作成
        const matchs = regexs.map(regex => {
          if(title.match(regex)) {
            return true;
          } else if(dateStrings.match(regex)) {
            return true;
          } else if(author.match(regex)) {
            return true;
          } else if(gameSystemName.match(regex)) {
            return true;
          } else if(description.match(regex)) {
            return true;
          } else {
            return false;
          }
        });
        // すべてのマッチリストがtrue なら最終結果trueを返す
        if(matchs.length === 1) {
          return matchs[0];
        } else {
          let matchResult = true;
          for(let i = 0;i < matchs.length;i++) {
            matchResult = (matchResult && matchs[i]);
          }
          return matchResult;
        }
      }
    },
    checkShowFavoriteList(isFavorited) {
      if(this.filterSet.showFavoriteList === false) {
        return true
      } else if (this.filterSet.showFavoriteList === true && isFavorited) {
        return true
      } else {
        return false
      }
    },

    // お気に入り関連メソッド
    async checkFavoriteListOnLoad() {
      this.favoriteUrlList = await localStorage.getItem("favoriteUrlList") ? JSON.parse(localStorage.getItem("favoriteUrlList")) : [];
      console.log(`local favoriteUrlList is `);
      console.log(this.favoriteUrlList);
      this.favoriteUrlList.forEach(url => {
        for (let i = 0;i < this.sessionsRaw.length;i++) {
          if (url === this.sessionsRaw[i].url) {
            this.sessionsRaw[i].isFavorited = true;
            console.log(`${this.sessionsRaw[i].url} is fav`);
            console.log(`${this.sessionsRaw[i].isFavorited}`);
          }
        }
      });
    },
    addKeywordGameSystem(word) {
      if(this.filterSet.keywordBuffer === null) {
        console.log('null でした');
        this.filterSet.keywordBuffer = '#' + word + ' ';
        return
      } else {
        const keywords = this.filterSet.keywordBuffer
          .split(/[\u{20}\u{3000}]/u)
          .filter(keyword => keyword !== "")
          .filter(keyword => keyword.indexOf('#') === -1);
        this.filterSet.keywordBuffer = keywords.join(' ') + ' #' + word + ' ';
      }
      // this.setFilterKeyword();
        this.filterSet.keywords = this.filterSet.keywordBuffer
          .split(/[\u{20}\u{3000}]/u)
          .filter(keyword => keyword !== "");
        console.log({keywordBuffer : this.filterSet.keywordBuffer});
        console.log({keywords : this.filterSet.keywords});
      console.log(`${this.filterSet.keywordBuffer}`);
    },
    toggleFavorite(clickedUrl) {
      console.log(`toggle Fav ${clickedUrl}`);
      // すでにURLが存在していれば削除
      if(this.favoriteUrlList.indexOf(clickedUrl) > -1) {
        this.sessions.forEach(session => {
          if(session.url === clickedUrl) {
            session.isFavorited = false;
          }
        });
        this.favoriteUrlList = this.favoriteUrlList.filter(url => url !== clickedUrl)
      // URLがなければ追加
      } else {
        this.sessions.forEach(session => {
          if(session.url === clickedUrl) {
            session.isFavorited = true;
          }
        });
        // 追加
        this.favoriteUrlList.unshift(clickedUrl);
        // 重複していたら削除
        this.favoriteUrlList = this.favoriteUrlList.filter((x, i, self) => self.indexOf(x) === i);
      }
      localStorage.setItem("favoriteUrlList", JSON.stringify(this.favoriteUrlList));
      console.log(this.favoriteUrlList);
    },
    showFavorite() {
      console.log(this.favoriteUrlList.length);
      const favoriteUrlList = this.favoriteUrlList.map(url => {
        for(let i = 0;i < this.sessions.length;i++) {
          if(this.sessions[i].url === url) {
            return this.sessions[i];
          }
        }
      });
      favoriteUrlList.forEach(session => console.log(session.title))
    },
  },

  computed: {
    selectedSessions() {
      return this.sessions.filter(session => 
        this.checkStatus(session.status_id)
      ).filter(session => 
        this.checkDays(session.date)
      ).filter(session => 
        this.checkArea(session.venue)
      ).filter(session => 
        this.checkGameSystem(session.game_system_name)
      ).filter(session => 
        this.checkKeyword(session.title, session.date_strings, session.author, session.description, session.game_system_name)
      ).filter(session => 
        this.checkShowFavoriteList(session.isFavorited)
      ).sort((a, b) => {
        return (a.date_value < b.date_value) ? -1 : (a.date_value > b.date_value) ? 1 : 0;
      });
    },
    selectedSessionsView() {
      return this.selectedSessions.slice(0, 100);
    },
  },

  mounted () {
    localStorage.removeItem("localSessions");
    if (!window.navigator.onLine) {
      console.log('offline');
      this.isLoading = false;
      this.isOffline = true;
    } else {
      this.getSessions();
    }
  },
}
</script>

<style scoped>
#keep .v-navigation-drawer__border {
  display: none
}

h1 {
  text-shadow: 3px 3px 15px #111;
}
p.subtitle {
  text-shadow: 2px 2px 5px #111;
}
.navlink-parent{
	position: relative;
	height: 80px;
}
.navlink-children{
	position: absolute;
	bottom: 0;
}
</style>
