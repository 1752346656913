<template>
  <v-container>
    <v-row  class="py-1">
      <v-col cols="12" class="py-0">
        <v-card
          class="mx-auto"
        >
          <v-list-item
            :class="[ session.isPast ? 'grey' : 'green']"
            dark
          >
            <v-list-item-content>
              <v-list-item-title
                class="headline font-weight-bold"
              >
                {{ session.title }}
              </v-list-item-title>
              <v-btn
                @click="toggleFavorite"
                :class="[ isFavorited ? 'mx-2 pink lighten-1' : 'mx-2 grey lighten-1']"
                dark
                small
                absolute
                top
                right
                fab
              >
                <v-icon>mdi-heart</v-icon>
              </v-btn>
            </v-list-item-content>

          </v-list-item>
          <v-container
            class="pa-3 pb-0 ma-0"
          >
            <v-row align="start" no-gutters>
              <v-col
                @click="addKeywordGameSystem"
                cols="12"
                lg="2"
                class="pa-0 ma-0"
              >
                <v-list-item-title>
                  <v-icon>mdi-book-open-page-variant</v-icon>
                  <span
                    class="pl-2"
                  >{{ session.game_system_name }}</span>
                </v-list-item-title>
              </v-col>
              <v-col
                cols="12"
                lg="4"
                class="pa-0 ma-0"
              >
                <v-list-item-title>
                <a
                  :href="addCalendar"
                  target="_blank"
                >
                  <v-icon>mdi-calendar-today</v-icon>
                  <span class="pl-2">{{ session.date_strings }}</span>
                </a>
                </v-list-item-title>
              </v-col>
              <v-col
                cols="12"
                lg="4"
                class="pa-0 ma-0"
              >
                <v-list-item-title>
                  <v-icon>mdi-map-marker</v-icon>
                  <a
                    class="pa-0 ma-0 pl-2"
                    :href="session.venue_url"
                    target="_blank"
                  >{{ session.venue }}</a>
                </v-list-item-title>
              </v-col>
              <v-col
                cols="12"
                lg="2"
                class="pa-0 ma-0"
              >
                <v-list-item-title>
                  <v-icon>mdi-account</v-icon>
                  <span class="pl-2">{{ session.author }}</span>
                </v-list-item-title>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-list-item-title>
                  <span>
                  {{ session.previewText.slice(0, 100) }}
                  </span>
                </v-list-item-title>
              </v-col>
            </v-row>
          </v-container>

          <v-card-actions>
              <v-btn
                link
                :href="session.url"
                target="_blank"
                class="ma-0 px-3"
                :color="session.status_id === 1 ? 'green' : 'grey'"
                rounded
                dark
                outlined
              ><v-icon>mdi-account-circle</v-icon>{{ session.status }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="show = !show"
            >
              <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-actions>
          <v-expand-transition>
            <div v-if="show">
              <v-divider></v-divider>
              <v-card-text>
                <p>{{ session.title }}</p>
                <p>{{ session.date_strings }}</p>
                <p>{{ session.venue }}</p>
                <p>{{ session.status }}</p>
                <p>{{ session.author }}</p>
                <p>{{ session.previewText }}</p>
                <v-spacer />
                <v-btn
                  class="ma-0 px-3"
                  color="green"
                  right
                  text
                  :href="session.url"
                  target="_blank"
                >
                  詳細ページへ
                </v-btn>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Session', // このコンポーネントのエクスポート名

  props: {
    session: Object
  },

  data: () => ({
    show: false,
    isFavorited: false
  }),

  methods: {
    addKeywordGameSystem() {
      console.log(this.session.game_system_name);
      this.$emit('add-keyword-game-system', this.session.game_system_name);
    },
    toggleFavorite() {
      this.$emit('toggle-favorite', this.session.url);
      console.log(this.session);
      this.checkIsFavorite();
      // this.isFavorited = !this.isFavorited;
      // this.isFavorited = this.session.isFavorited;
    },
    checkIsFavorite() {
      this.isFavorited = this.session.isFavorited;
    },
  },

  computed: {
    addCalendar() {
      const getUTCStart = (date_str) => {
        const date = new Date(date_str);
        date.setDate(date.getDate() + 1);
        return date.getUTCFullYear() +
          zerofill(date.getUTCMonth()+1) +
          zerofill(date.getUTCDate());
      };
      const getUTCEnd = (date_str) => {
        const date = new Date(date_str);
        date.setDate(date.getDate() + 2);
        return date.getUTCFullYear() +
          zerofill(date.getUTCMonth()+1) +
          zerofill(date.getUTCDate());
      };
      const zerofill = (num) => ('0'+num).slice(-2);
      return 'http://www.google.com/calendar/event?' +
        'action='   + 'TEMPLATE' +
        '&text='    + encodeURIComponent(this.session.title) +
        '&details=' + encodeURIComponent(this.session.url) +
        '&location='+ encodeURIComponent(this.session.venue) +
        '&dates='   + getUTCStart(this.session.date) + '/' + getUTCEnd(this.session.date) +
        '&trp='     + 'false' +
        '&sprop='   + encodeURIComponent('リンク設置元のURL') +
        '&sprop='   + 'name:' + encodeURIComponent('リンク設置元のサービス名');
    },
  },

  mounted () {
    (() => {
      this.checkIsFavorite();
    })();
  },

  updated () {
    (() => {
      this.checkIsFavorite();
    })();
  },
}
</script>

<style scoped>
</style>
