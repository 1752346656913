<template>
  <v-footer
    clipped
    padless
    color="green"
    class="font-weight-medium"
  >
    <v-row
      justify="center"
      no-gutters
    >
      <v-btn
        text
        rounded
        class="my-2"
        to="/"
      >
        Home
      </v-btn>
      <v-btn
        text
        rounded
        class="my-2"
        to="/about"
      >
        About Us
      </v-btn>
      <v-btn
        text
        rounded
        class="my-2"
        to="/privacy"
      >
        Privacy
      </v-btn>
      <v-col
        class="grey darken-3 py-0 text-center white--text"
        cols="12"
      >
        {{ new Date().getFullYear() }} — trpgcalendar — 本サイトは個人による運営です。本サイトを利用したことによるいかなる狂気・心身の損害も保証致しかねます。
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer', // このコンポーネントのエクスポート名
}
</script>

<style scoped>
</style>
