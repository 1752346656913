<template>
  <v-app>
    <v-app-bar app clipped-left color="green">
      <router-link to="/" >
        <v-icon>mdi-home</v-icon>
      </router-link>
      <!-- タイトル -->
      <span class="ml-3 mr-5 mb-0">TRPG <span class="font-weight-light">Calendar</span></span>
    </v-app-bar>

    <v-main class="green darken-4">
      <v-container fluid>
        <v-row align="center" justify="center">
          <v-col class="text-center" cols="12">
            <h1 elevation="3" class="display-2 font-weight-bold mb-4">プライバシー ポリシー</h1>
            <p elevation="3" class="subtitle font-weight-bold">Privacy Policy</p>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid class="">
        <v-row align="center" justify="center">
        <v-col class="" cols="12">
          <div class="text-body" justify="center">
            <p class="py-0 my-0 mx-3">(English follows Japanese)</p>
            <p class="pt-4 my-0 mx-3">当サイト(trpgcalendar.com)では、個人情報の取扱いに関し、個人情報保護法をはじめ個人情報保護に関する関係諸法令、および関連する政省令、ガイドライン等を遵守することにより、個人情報の適切な収集・利用・安全管理に努めます。</p>
            <h2 class="pt-4 my-0 mx-3">個人情報の利用目的</h2>
            <p class="py-0 my-0 mx-3">当サイトでは、お問い合わせフォームからのご連絡の際に、名前、会社名、メールアドレス等の個人情報をご登録いただく場合がございます。</p>
            <p class="py-0 my-0 mx-3">これらの個人情報は、お問い合わせに対する回答の際に利用させていただくものであり、その目的以外では利用いたしません。</p>
            <h2 class="pt-4 my-0 mx-3">個人情報の第三者への開示</h2>
            <p class="py-0 my-0 mx-3">当サイトでは、個人情報は適切に管理し、以下に該当する場合を除いて第三者に開示することはありません。</p>
            <p class="py-0 my-0 mx-3">・本人の事前の同意がある場合<br>・個人を識別し得ない「統計データ」として開示する場合<br>・国の機関または地方公共団体から、法令の定める事務を遂行するために協力を求められた場合</p>
            <h2 class="pt-4 my-0 mx-3">個人情報の開示、訂正、削除</h2>
            <p class="py-0 my-0 mx-3">ご本人からの個人データの開示、訂正、削除のご希望の場合には、ご本人であることを確認させていただいたうえ、速やかに対応させていただきます。</p>
            <h2 class="pt-4 my-0 mx-3">アクセス解析ツールについて</h2>
            <p class="py-0 my-0 mx-3">当サイトでは、サイトの利用状況を調べ、サイトの改善に役立てるため、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。Googleアナリティクスはトラフィックデータの収集のためにCookie（クッキー）を使用しています。このトラフィックデータは匿名で収集されており、個人を特定するものではありません。Cookieを無効にすることで、この機能によるデータ収集を拒否することができますので、お使いのブラウザの設定をご確認ください。</p>
            <h2 class="pt-4 my-0 mx-3">免責事項</h2>
            <p class="py-0 my-0 mx-3">当サイトのコンテンツ・情報につきましては、可能な限り正確な情報を掲載するよう努めておりますが、誤情報のある場合や、情報が古くなっている場合もございます。</p>
            <p class="py-0 my-0 mx-3">当サイトに掲載された内容によって生じた損害等については、一切の責任を負いかねますのでご了承ください。</p><br><br>
            <h2 class="pt-4 my-0 mx-3" id="Privacy_en"><u>Privacy Policy</u></h2>
            <p class="py-0 my-0 mx-3">The owner of trpgcalendar.com (“the owner”) strives to appropriately collect, use, and manage personal information by complying with the Personal Information Protection Law and other related laws and regulations regarding the protection of personal information, as well as related governmental ordinances and guidelines.</p>
            <h2 class="pt-4 my-0 mx-3">Usage of information</h2>
            <p class="py-0 my-0 mx-3">This web site collects personal information of the visitors, such as the visitor's name and email address, when the visitor use the contact form. The personal information shall be used only to protect rights or properties of the web site owner or its visitors and to send reply to the contact.</p>
            <h2 class="pt-4 my-0 mx-3">Sharing of information</h2>
            <p class="py-0 my-0 mx-3">The owner shall not be entitled to share the personal information of the visitors with third parties, except under the following circumstances: </p>
            <p class="py-0 my-0 mx-3">・When the visitor gives prior consent<br>・When disclosed as “statistical data” by which an individual cannot be distinguished<br>・When the owner has a good faith belief that the  disclosure is reasonably necessary to comply with the law</p>
            <h2 class="pt-4 my-0 mx-3">Disclosure, correction, deletion and suspension of information</h2>
            <p class="py-0 my-0 mx-3">When the owner of this web site is requested to disclose, correct, delete or suspend the use of personal information by the person himself/herself that provided the relevant information, the owner shall deal with the matter swiftly as soon as the identification of the person is confirmed.</p>
            <h2 class="pt-4 my-0 mx-3">On the use of the access analysis tool</h2>
            <p class="py-0 my-0 mx-3">This site uses “Google Analytics” to collect information about the use of this site. The owner uses the information from Google Analytics only to improve the website contents. Google Analytics does not collect identifying information. Google Analytics plants a permanent cookie on the visitor’s web browser to identify the visitor as a unique user the next time you visit this site. You can prevent Google Analytics from recognizing you on return visits to this site by disabling Cookies on your browser.</p>
            <h2 class="pt-4 my-0 mx-3">Disclaimer</h2>
            <p class="py-0 my-0 mx-3">While the owner has made every attempt to ensure that the information contained in this site is correct and updated, the owner is not responsible for any errors or omissions, or for any loss or other detriments as a result of the data in this website.</p>
          </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>


    <foot></foot>
  </v-app>
</template>

<script>
import Foot from './Footer';
export default {
  name: 'Privacy',

  components: {
    Foot,
  },
}
</script>

<style scoped>
h1 {
  color: #fff;
  text-shadow: 3px 3px 15px #111;
}
p.subtitle {
  color: #fff;
  text-shadow: 2px 2px 5px #111;
}
p, h2 {
  color: #fff;
  text-shadow: 1px 1px 3px #111;
}
.text-body {
  max-width: 1200px;
  margin-left: auto!important;
  margin-right: auto!important;
}
</style>
